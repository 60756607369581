import React from "react";
import { Link } from "gatsby";
import { Cookies } from "react-cookie-consent";
import styled from "styled-components";

import Layout from "@common/Layout";
import { Container } from "@components/global";
import Navbar from "@common/Navbar";

const SecondPage = () => (
  <Layout>
    <Navbar hideItems isImprint />
    <ContentContainer>
      <h1>Impressum</h1>
      <SmallP>
        <br />
        Inhaber und Verantwortlicher der Webseite
        www.physio-luebeck-grieshaber.de ist:
        <br />
      </SmallP>
      <h3>
        Maike Lübeck-Grieshaber
        <br />
        Ostheopathin, Physiotherapeutin
      </h3>
      <SmallP>
        Praxis für Physiotherapie und Osteopathie
        <br />
        <br />
        Maike Lübeck-Grieshaber
        <br />
        Friedrichstr. 16
        <br />
        78112 St. Georgen
        <br />
        <br />
        Tel.: 07724-9160550 <br />
        Mobil: 0152-21584277 <br />
        Fax: 07724-9160560 <br />
      </SmallP>
      <SmallP>email: info@physio-luebeck-grieshaber.de</SmallP>
      <SmallP></SmallP>
      <br />
      <br />
      <h3>1. Inhalt des Onlineangebotes</h3>
      <SmallP>
        Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit,
        Vollständigkeit oder Qualität der bereitgestellten Informationen.
        Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller
        oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der
        dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und
        unvollständiger Informationen verursacht wurden, sind grundsätzlich
        ausgeschlossen, sofern seitens des Autors kein nachweislich
        vorsätzliches oder grob fahrlässiges Verschulden vorliegt. Alle Angebote
        sind freibleibend und unverbindlich. Der Autor behält es sich
        ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne
        gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
        Veröffentlichung zeitweise oder endgültig einzustellen.
      </SmallP>
      <SmallP></SmallP>
      <h3>2. Verweise und Links</h3>
      <SmallP>
        Bei direkten oder indirekten Verweisen auf fremde Internetseiten
        ("Links"), die außerhalb des Verantwortungsbereiches des Autors liegen,
        würde eine Haftungsverpflichtung ausschließlich in dem Fall in Kraft
        treten, in dem der Autor von den Inhalten Kenntnis hat und es ihm
        technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger
        Inhalte zu verhindern. Der Autor erklärt hiermit ausdrücklich, dass zum
        Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu
        verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige
        Gestaltung, die Inhalte oder die Urheberschaft der gelinkten/verknüpften
        Seiten hat der Autor keinerlei Einfluss. Deshalb distanziert er sich
        hiermit ausdrücklich von allen Inhalten aller gelinkten /verknüpften
        Seiten, die nach der Linksetzung verändert wurden. Diese Feststellung
        gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links
        und Verweise sowie für Fremdeinträge in vom Autor eingerichteten
        Gästebüchern, Diskussionsforen und Mailinglisten. Für illegale,
        fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden,
        die aus der Nutzung oder Nichtnutzung solcherart dargebotener
        Informationen entstehen, haftet allein der Anbieter der Seite, auf
        welche verwiesen wurde, nicht derjenige, der über Links auf die
        jeweilige Veröffentlichung lediglich verweist.
      </SmallP>
      <h3>3. Urheberrecht</h3>
      <SmallP>
        Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der
        verwendeten Grafiken, Videos, Sounds und Texte zu beachten, von ihm
        selbst erstellte Grafiken, Videos, Sounds und Texte zu nutzen oder auf
        lizenzfreie Grafiken, Sounds und Texte zurückzugreifen. Sollte sich auf
        den jeweiligen Seiten dennoch eine ungekennzeichnete, aber durch fremdes
        Copyright geschützte Grafik, ein Sound oder Text befinden, so konnte das
        Copyright vom Autor nicht festgestellt werden. Im Falle einer solchen
        unbeabsichtigten Copyrightverletzung werden wir das entsprechende Objekt
        nach Benachrichtigung aus unserer Publikation entfernen bzw. mit dem
        entsprechenden Copyright kenntlich machen.
      </SmallP>
      <h3>4. Datenschutz</h3>
      <br />
      <a
        style={{ textDecoration: "underline" }}
        onClick={() => {
          Cookies.set("CookieConsent", "false");
          window.location.reload();
        }}
      >
        Klicken Sie hier, um nur notwendig Cookies zu setzten.
      </a>
      <br />
      <br />
      <SmallP>
        <strong>
          Allgemeiner Hinweis und Pflichtinformationen sowie Benennung der
          verantwortlichen Stelle.
        </strong>
      </SmallP>
      <SmallP>
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
        ist: <br />
        <br />
      </SmallP>
      <SmallP>
        <span id="s3-t-firma">Praxis für Physiotherapie und Osteopathie</span>
        <br />
        <span id="s3-t-ansprechpartner">Maike Lübeck-Grieshaber</span>
        <br />
        <span id="s3-t-strasse">Friedrichstr. 16</span>
        <br />
        <span id="s3-t-plz">78112</span> <span id="s3-t-ort">St. Georgen</span>
        <br />
        <br />
      </SmallP>
      <SmallP></SmallP>
      <SmallP>
        Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen
        über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
        (z.B. Namen, Kontaktdaten o. Ä.).
      </SmallP>
      <SmallP>
        <br />
        <br />
        <strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong>
        <br />
      </SmallP>
      <SmallP>
        Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der
        Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten
        Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
        formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf
        erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
      </SmallP>
      <SmallP>
        <strong>
          <br />
          <br />
          Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde <br />
        </strong>
      </SmallP>
      <SmallP>
        Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
        Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
        Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist
        der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz
        unseres Unternehmens befindet. Der folgende Link stellt eine Liste der
        Datenschutzbeauftragten sowie deren Kontaktdaten bereit:{" "}
        <a
          href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
          target="_blank"
        >
          https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
        </a>
        .
      </SmallP>
      <SmallP>
        <br />
        <br />
        <strong>Recht auf Datenübertragbarkeit</strong>
        <br />
      </SmallP>
      <SmallP>
        Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer
        Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten,
        an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt
        in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung
        der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
        soweit es technisch machbar ist.
      </SmallP>
      <SmallP>
        <br />
        <br />
        <strong>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</strong>
        <br />
      </SmallP>
      <SmallP>
        Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen
        das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
        personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den
        Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung,
        Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren
        Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über
        die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.
      </SmallP>
      <SmallP>
        <br />
        <br />
        <strong>SSL- bzw. TLS-Verschlüsselung</strong>
        <br />
      </SmallP>
      <SmallP>
        Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
        Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website
        eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese
        Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine
        verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers
        und am Schloss-Symbol in der Browserzeile.
      </SmallP>
      <SmallP>
        <br />
        <br />
        <strong>Datenschutzbeauftragter</strong>
        <br />
      </SmallP>
      <SmallP>Wir haben einen Datenschutzbeauftragten bestellt.</SmallP>
      <br />
      <br />
      <SmallP>
        Maike Lübeck-Grieshaber
        <br />
        Friedrichstr. 16
        <br />
        78112 St. Georgen
        <br />
        <br />
        Telefon: 07724 9160550
        <br />
        E-Mail: info@physio-luebeck-grieshaber.de
      </SmallP>
      <SmallP>
        <br />
        <br />
        <strong>Google Maps</strong>
        <br />
      </SmallP>
      <SmallP>
        Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter
        ist die
        <br />
        <br />
        Google Inc.
        <br />
        1600 Amphitheatre Parkway
        <br />
        Mountain View, CA 94043
        <br />
        USA.
        <br />
        <br />
        Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP
        Adresse zu speichern. Diese Informationen werden in der Regel an einen
        Server von Google in den USA übertragen und dort gespeichert. Der
        Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.
        <br />
        Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden
        Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit
        der von uns auf der Website angegebenen Orte. Dies stellt ein
        berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
        <br />
        Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
        Datenschutzerklärung von Google:
        https://www.google.de/intl/de/policies/privacy/.
      </SmallP>
      <br />
      <br />
      <SmallP></SmallP>
      <h3> 5. Rechtswirksamkeit dieses Haftungsausschlusses</h3>
      <SmallP>
        Dieser Haftungsausschluss ist als Teil des Internetangebotes zu
        betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile
        oder einzelne Formulierungen dieses Textes der geltenden Rechtslage
        nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben
        die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit
        davon unberührt.{" "}
      </SmallP>
      <SmallP></SmallP>
      <SmallP>&nbsp;</SmallP>
      <br />
      <br />
      <br />
      <Link to="/">Zurück zur Seite</Link>
      <br />
      <br />
      <br />
    </ContentContainer>
  </Layout>
);

const ContentContainer = styled(Container)`
  margin-top: 5rem;
  width: 60%;
`;

const SmallP = styled.p`
  font-size: 12pt;
`;

export default SecondPage;
